

// Show image when upload | add remove button

export var loadFile = function(event) {
    var reader = new FileReader();
    reader.onload = function(){
      var output = document.getElementById('output');
      output.src = reader.result;
    
      var removeButton = document.getElementById("removeImage");
      removeButton.setAttribute("type", "button");
    
      var uploadButton = document.getElementById("uploadImage");
      uploadImage.classList.add("hidden");
    
      var image = document.getElementById('output');
      image.classList.remove("hidden");
    
    };
    reader.readAsDataURL(event.target.files[0]);
    };
    
    
    // Remove image
    
    export function removeButton() {
    var image = document.getElementById('output');
    image.removeAttribute("src");
    image.classList.add("hidden");
    
    var removeButton = document.getElementById("removeImage");
    removeButton.setAttribute("type", "hidden");
    
    document.getElementById('uploadImage').value = "";
    
    var uploadImage = document.getElementById("uploadImage");
    uploadImage.classList.remove("hidden");
    }


    // Remove uploaded file

    export function removeFileButton() {
    var image = document.getElementById('output');
    image.removeAttribute("src");
    image.classList.add("hidden");
    
    var removeButton = document.getElementById("removeFile");
    removeButton.setAttribute("type", "hidden");
    
    document.getElementById('uploadFile').value = "";
    
    var uploadFile = document.getElementById("uploadFile");
    uploadFile.classList.remove("hidden");
    }




    



    
    
    
    
     
    
    
    
    
    
    
    